@import "~bootstrap/scss/bootstrap";
@import "./colors.scss";

html, body {
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}

.App {
}

.loginScreen {
  background-color: transparent;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15px;

  .panel {
    width: 160px;
  }

  .logo {
    width: 250px;
    height: 250px;
    background: url(./biglogo.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-bottom: 0px;
  }

  .loginForm {
    width: 320px;
    height: 650px;
    background-color: $lightgray-bg-color;
    border-radius: 35px;
    margin-left: -90px;
    margin-top: 55px;
    padding: 30px;
    padding-top: 0px;
  }
}

p.subtitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: $text-black;
  text-align: left;
  margin-bottom: 30px;
}

.inputGroup {
  margin-bottom: 20px;
}

span {
  display: block;
  font-size: 10px !important;
  line-height: 15px;
  color: $text-lightgray;
}

small {
  display: block;
  font-size: 10px !important;
  font-weight: 700;
  margin-top: 10px;

  a {
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footerLogo {
  background: url(./simplepay_logo.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 140px;
  height: 75px;
  margin: 0 auto;
  margin-top: 32px;
}