@import "~bootstrap/scss/bootstrap";
@import "../../colors.scss";

.orderContainer {
    position: relative;
}

.cart-btn-off {
    background-color: $button-submit-light !important;
    width: 100%;
    height: 54px;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: $text-white !important;
    border: solid 3px $button-submit-dark !important;
    border-radius: 15px !important;
    margin-top: 15px;

    &:disabled {
        opacity: 0.2 !important;
    }
}

.cart-btn-on {
    background-color: $button-submit-dark !important;
    width: 100%;
    height: 54px;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: $text-white !important;
    border: solid 1px $button-submit-dark !important;
    border-radius: 15px !important;
    margin-top: 15px;

    &:disabled {
        opacity: 0.2 !important;
    }
}

.inputGroupOrder {
    margin-top: 30px;
}

.textInputOrder {
    padding: 15px !important;
    padding-left: 35px !important;
    font-size: 12px !important;
}

.inlineIconOrder {
    position: absolute;
    margin-left: 10px !important;
    margin-top: 17px;
}

.divider {
    margin-top: 30px;
    padding-top: 15px;
    text-align: right;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: $text-purple;
    border-top: dashed 1px $mediumgray-border-color;
}

.download-link {
    padding-top: 15px !important;
}

.paymentSupporter {
    margin-top: 2rem;
    text-align: center;
}