@import "~bootstrap/scss/bootstrap";
@import "../../colors.scss";

body {
    background-color: $red-bg-color;
}

.payment-response-container {
    width: 320px;
    height: 450px;
    background-color: $lightgray-bg-color;
    border-radius: 35px;
    margin-left: -90px;
    margin-top: 55px;
    padding: 30px;

    .statusIcon {
        font-size: 3rem;
        margin: 0.5rem;
    }

    .FINISHED {
        color: $green-color;
    }

    .SUCCESS {
        color: $yellow-color;
    }

    .FAILURE {
        color: $red-color;
    }

    .TIMEOUT {
        color: $yellow-color;
    }

    .ERROR {
        color: $red-color;
    }

    .CANCEL {
        color: $red-color;
    }

    .CANCELLED {
        color: $red-color;
    }

    .statusText {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .detailsText {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
}