@import "~bootstrap/scss/bootstrap";
@import "../../colors.scss";

.cartProduct {
    font-size: 16px;
    font-weight: 600;
    width: inherit;
    height: 39px;
    min-height: 39px;
    list-style: none;
    padding: 0px;
    padding-left: 20px;
    margin: 0px;
    background-color: transparent;

    strong {
        font-weight: 700;
        font-size: 14px;
        display: block;
        margin-left: 70px;
        margin-top: -40px;
    }

    small {
        font-weight: 500;
        font-size: 12px;
        display: block;
        margin-left: 70px;
        margin-top: 0px;
    }

    img {
        width: 40px;
    }
}

.qntTable td {
    vertical-align: top;
}

.trashIcon {
    cursor: pointer;
    color: $text-purple;
}