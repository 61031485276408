@import "~bootstrap/scss/bootstrap";
@import "../../colors.scss";

.qntButton {
    width: 26px;
    height: 26px;
    display: inline;
    border: solid 1px $mediumgray-border-color;
    border-radius: 5px;
    background-color: $input-bg-color;
    border-top-right-radius: 0px;
    color: $text-purple;
    font-size: 16px;
    font-weight: 600;
}

.qntCounter {
    display: inline;
    color: $text-purple;
    font-size: 16px !important;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
}

.qntTable {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: $text-purple;
}

.qntControll {
    text-align: right;
    padding-top: 15px;
}

.qntInfo {
    text-align: left;
    padding-top: 15px;
}
