@import "~bootstrap/scss/bootstrap";
@import "../../colors.scss";

body {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, $bg-dark 0%, $bg-light 100%) !important;
}

.headerLogo {
    display: block;
    width: 50px !important;
    height: 50px !important;
    background: url(../../smalllogo.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-top: 20px;
    margin-left: 20px;
}

.fullwidth {
    max-width: 100% !important;
}

.navigator {
    max-height: 75px !important;
    margin: 0px;
    padding: 0px !important;
}

.exitIcon {
    display: inline;
    position: absolute;
    right: 1.25rem;
    top: 1.75rem;
    color: white;
    cursor: pointer;
}

.userProfile {
    width: 250px !important;
    margin-right: 30px;
    
    .userHello {
        display: inline-block !important;
        color: $text-white !important;
        font-size: 18px !important;
        font-weight: 700 !important;
        font-style: normal !important;
        margin-right: 15px;
        margin-top: 0px;
        padding: 0px !important;

        .userType {
            display: block !important;
            color: $text-white !important;
            font-size: 12px !important;
            font-weight: 400 !important;
        }    
    }

    .userAvatar {
        width: 56px;
        height: 56px;
        border-radius: 169.029px;
        position: absolute;
        top: 10px;
    }
}

.board {
    width: 100%;
    height: 100vh;
    background-color: $lightgray-bg-color;

    .board-card {
        background: $text-white;
        height: 100vh;
        padding: 50px;
        padding-bottom: 0px;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar-thumb {
            background-color: transparent; /* color of the scroll thumb */
            border: none !important;
        }
        &:hover::-webkit-scrollbar-thumb {
            background-color: transparent; /* color of the scroll thumb */
            border: none !important;
        }    
    }

    .marged {
        border-right: solid 20px $lightgray-bg-color !important;
    }
}

.formLabel {
    font-size: 16px;
    font-weight: 700;
    color: $text-black;
    margin-bottom: 15px;
    margin-top: 15px;
}

.firstLabel {
    margin-top: 0px;
}

.qntSum {
    margin-top: 15px;
    padding-top: 15px;
    text-align: right;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: $text-purple;
    border-top: dashed 1px $mediumgray-border-color;
}

.cart-btn {
    background-color: $button-submit-dark !important;
    width: 100%;
    height: 54px;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: $text-white !important;
    border: solid 1px $button-submit-dark !important;
    border-radius: 15px !important;
    margin-top: 15px;
}

.disabled-panel {
    width: 64vw;
    height: 100vh;
    position: fixed;
    top: 75px;
}

.close-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
}

.centerizedText {
    text-align: center;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

.bigButton {
    width: 100px;
    height: 100px;
    background-color: $red-bg-color;
    border: none;
    border-radius: 90%;
    align-self: center;

    .homeIcon {
        font-size: 2rem;
        color: $lightgray-bg-color;
    }
}

.full-width-calendar {
    width: 100% !important;
}

.max-persons {
    display: block;
    margin: 0px;
    margin-bottom: 8px;
    color: #999;
}