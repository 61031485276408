@import "~bootstrap/scss/bootstrap";
@import "../../colors.scss";

.comboListBoxContainer {
    width: 100%;
    height: 55px;
    position: relative;
    background-color: $input-bg-color;
    border: solid 1px $purple-border-color;
    border-radius: 20px;
    text-align: left;

    .angle {
        float: right;
        margin-right: 10px;
        margin-top: 20px;
    }

    ul.selectedOption {
        margin: 0px;
        padding-top: 7px;
        padding-left: 0px;

        .comboListBoxOptionSelectedImaged {
            width: inherit;
            height: 39px;
            min-height: 39px;
            list-style: none;
            padding: 0px;
            padding-left: 20px;
            margin: 0px;
            background-color: transparent;

            strong {
                font-weight: 700;
                font-size: 14px;
                display: block;
                margin-left: 70px;
                margin-top: -40px;
            }

            small {
                font-weight: 500;
                font-size: 12px;
                display: block;
                margin-left: 70px;
                margin-top: 0px;
            }

            img {
                width: 40px;
            }
        }

        .comboListBoxOptionSelectedSimple {
            width: inherit;
            height: 39px;
            min-height: 39px;
            list-style: none;
            padding: 0px;
            padding-left: 20px;
            margin: 0px;
            background-color: transparent;

            strong {
                font-weight: 700;
                font-size: 14px;
                display: block;
                margin-top: 10px;
            }

            small {
                font-weight: 500;
                font-size: 12px;
                display: block;
                margin-top: 20px;
            }
        }
    }

    ul.optionsList {
        box-shadow: 2px 2px 13px -3px rgba(0,0,0,0.4);
        border: solid 1px $lightgray-bg-color;
        background-color: $input-bg-color;
        margin: 0px;
        padding: 0px;
        padding-left: 20px;
        margin-top: 5px;
        border-radius: 20px;
        position: absolute;
        width: inherit;
        max-width: 366px;
        z-index: 999;

        .comboListBoxOptionImaged {
            width: 98%;
            height: 39px;
            min-height: 39px;
            list-style: none;
            padding: 0px;
            margin: 0px;
            margin-top: 5px;
            margin-bottom: 5px;
            background-color: $text-white;
            font-size: 12px;
            cursor: pointer;

            strong {
                font-weight: 700;
                font-size: 14px;
                display: block;
                margin-left: 70px;
                margin-top: -40px;
            }

            small {
                font-weight: 500;
                font-size: 12px;
                display: block;
                margin-left: 70px;
                margin-top: 0px;
            }
            
            img {
                width: 40px;
            }

            &:hover {
                color: $text-lightgray;
            }
        }

        .comboListBoxOptionSimple {
            width: 98%;
            height: 39px;
            min-height: 39px;
            list-style: none;
            padding: 0px;
            margin: 0px;
            margin-top: 5px;
            margin-bottom: 5px;
            background-color: $text-white;
            font-size: 12px;
            cursor: pointer;

            strong {
                font-weight: 700;
                font-size: 14px;
                display: block;
                margin-top: 10px;
            }

            small {
                font-weight: 500;
                font-size: 12px;
                display: block;
                margin-top: 20px;
            }

            &:hover {
                color: $text-lightgray;
            }
        }
    }
}