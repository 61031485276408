@import "~bootstrap/scss/bootstrap";
@import "../../colors.scss";

body {
    background-color: $red-bg-color;
}

.payment-response-container {
    width: 320px;
    height: 450px;
    background-color: $lightgray-bg-color;
    border-radius: 35px;
    margin-left: -90px;
    margin-top: 0px;
    padding: 30px;

    .statusText {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .detailsText {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
}