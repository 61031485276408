@import "~bootstrap/scss/bootstrap";
@import "../../colors.scss";

body {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, $bg-light 0%, $bg-dark 100%);
}

.textInputLogin {
  padding: 15px !important;
  padding-left: 35px !important;
  font-size: 12px !important;
}

.inputGroup {
    position: relative;
}

.inlineIcon {
    position: absolute;
    margin-left: -120px;
    margin-top: 17px;
}

.showhidePass {
    position: absolute;
    margin-top: -38px;
    margin-left: 60px;
    font-size: 12px;
    min-width: 60px;
    background-color: $button-color-lightblue !important;

    &:hover {
        background-color: $button-color-darkblue !important;
    }
}

.loginBtn {
    width: 100%;
    min-height: 40px;
    background-color: $button-submit-dark !important;
    border: transparent !important;
    opacity: 1;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    &:hover {
        background-color: $button-color-darkblue !important;
        border: transparent;
        opacity: 0.7;
    }

    &:focus {
        background-color: $button-submit-dark !important;
        border: transparent;
    }
}