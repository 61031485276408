$bg-dark: #062F4D;
$bg-light: #3FA9F5;

$button-color-lightblue: #E8F1FF;
$button-color-darkblue: #3FA9F5;
$button-submit-dark: #062F4D;
$button-submit-light: rgba(6, 47, 77, 0.37);

$red-bg-color: #8B112C;
$lightgray-bg-color: #D9D9D9;
$input-bg-color: #ffffff;
$pinky-bg-color: rgba(139,17,44,0.4);

$lightgray-border-color: #969AA8;
$white-border-color: #ECEBF0;
$purple-border-color: #271963;
$mediumgray-border-color: #C8C8D3;

$text-lightgray: #969AA8;
$text-black: #212121;
$text-white: #ffffff;
$text-purple: #272459;

$green-color: #00C853;
$red-color: #8B112C;
$yellow-color: #FFD600;